import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import InteractiveTable from 'components/common/interactive_table';
import { Number } from 'components/common/number';
import RecommendedAnswersIcon from 'components/common/icons/recommended_answers_icon';
import Tooltip from 'components/common/tooltip';

export function AnswerPerformanceTable({ data, isLoading }) {
  const showRecommendedAnswers = data?.some(row => row.recommendedAnswers);
  const transformedData = data?.map(row => ({
    id: row.id,
    recommendedAnswersIcon: showRecommendedAnswers && (
      <IconCell>
        {row.recommendedAnswers && (
          <Tooltip message={`${row.recommendedAnswers}`}>
            <RecommendedAnswersIcon width="48px" />
          </Tooltip>
        )}
      </IconCell>
    ),
    name: <NameCell>{row.name}</NameCell>,
    references: <Number value={row.references} />,
    handoffs: <Number value={row.handoffs} />,
    resolved: <Number value={row.resolved} />,
    resolutionRate: (
      <span>
        <Number value={row.resolutionRate} />%
      </span>
    ),
  }));

  const columns = [
    ...(showRecommendedAnswers
      ? [
          {
            header: '',
            accessor: 'recommendedAnswersIcon',
            align: 'center',
            sortValue: item => item.recommendedAnswers || -1,
          },
        ]
      : []),
    {
      header: 'Answer',
      accessor: 'name',
      align: 'left',
      // item is the React component passed in the transformedData above, so the value we want to sort
      // is the value of the child component, which is the text content of the NameCell
      sortValue: item => data.find(d => d.id === item.id)?.name,
    },
    {
      header: 'Count',
      accessor: 'references',
      align: 'right',
      // item is the React component passed in the transformedData above, so the value we want to sort
      // is the value prop passed in to the Number component
      sortValue: item => data.find(d => d.id === item.id)?.references,
    },
    {
      header: 'Handed off',
      accessor: 'handoffs',
      align: 'right',
      sortValue: item => data.find(d => d.id === item.id)?.handoffs,
    },
    {
      header: 'Resolved',
      accessor: 'resolved',
      align: 'right',
      sortValue: item => data.find(d => d.id === item.id)?.resolved,
    },
    {
      header: 'Resolution rate',
      accessor: 'resolutionRate',
      align: 'right',
      sortValue: item => data.find(d => d.id === item.id)?.resolutionRate,
    },
  ];

  return (
    <InteractiveTable
      columns={columns}
      data={transformedData || []}
      enableSearch
      enableSorting
      isLoading={isLoading}
      rowsPerPage={25}
    />
  );
}

AnswerPerformanceTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      references: PropTypes.number.isRequired,
      handoffs: PropTypes.number.isRequired,
      resolved: PropTypes.number.isRequired,
      resolutionRate: PropTypes.number.isRequired,
      recommendedAnswers: PropTypes.number,
    })
  ),
  isLoading: PropTypes.bool,
};

const NameCell = styled.div`
  align-items: center;
  display: flex;
  gap: ${p => p.theme.spacing.small};
`;

const IconCell = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: auto;
`;

function mapStateToProps({ getProvider }) {
  const answerPerformancesProvider = getProvider('answerPerformances');
  const data = answerPerformancesProvider.findAll();

  return {
    data,
    isLoading: answerPerformancesProvider.isLoading(),
  };
}

const AnswerPerformanceTableContainer = connect(mapStateToProps)(AnswerPerformanceTable);

export default AnswerPerformanceTableContainer;
