import _ from 'lodash';
import createModel, { prop } from './lib/create_model';

const Role = createModel({
  modelName: 'Role',

  properties: {
    id: prop(String).isRequired,
    name: prop(String).isRequired,
  },
});

export default Role;

const SystemAdminRole = new Role({ id: 'system-admin', name: 'Administrator' });
const ComplianceAdminRole = new Role({ id: 'compliance-admin', name: 'Compliance Administrator' });
const TeamManagerRole = new Role({ id: 'team-manager', name: 'Team Manager' });
const AnalystRole = new Role({ id: 'analyst', name: 'Analyst' });
const DeveloperRole = new Role({ id: 'developer', name: 'Developer' });

const ManagerRoles = [SystemAdminRole, ComplianceAdminRole, TeamManagerRole];

const Roles = Object.freeze(
  _.keyBy(
    [
      new Role({ id: 'agent', name: 'Agent' }),
      new Role({ id: 'agent-plus', name: 'Agent Plus' }),
      new Role({ id: 'answers-admin', name: 'Answers Administrator' }),
      new Role({ id: 'task-user', name: 'Task User' }),
      new Role({ id: 'api-user', name: 'API User' }),
      SystemAdminRole,
      TeamManagerRole,
      new Role({ id: 'liveboards', name: 'Liveboards Only' }),
      AnalystRole,
      ComplianceAdminRole,
      DeveloperRole,
    ],
    'id'
  )
);

export { Roles, ManagerRoles, AnalystRole, DeveloperRole, ComplianceAdminRole, SystemAdminRole };
