import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import Button from 'components/common/button';
import SearchInput from 'components/common/search_input';
import Spinner from 'scripts/presentation/components/common/spinner_two';
import { Table as CommonTable, TBody, THead, TR } from 'components/common/table';

const InteractiveTable = ({ columns, data, isLoading, rowsPerPage, enableSearch, enableSorting }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);

  // Sorting logic (only if sorting is enabled)
  const sortedData = useMemo(() => {
    if (!enableSorting || !sortConfig.key) return data;
    return [...data].sort((a, b) => {
      const column = columns.find(c => c.accessor === sortConfig.key);
      const getValue = item => column?.sortValue(item) || item[sortConfig.key];

      const aValue = getValue(a);
      const bValue = getValue(b);

      if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
  }, [enableSorting, sortConfig.key, sortConfig.direction, data, columns]);

  // Filtering logic (only if search is enabled)
  const filteredData = useMemo(() => {
    setCurrentPage(1);
    if (!enableSearch || !searchTerm) return sortedData;
    return sortedData.filter(item =>
      columns.some(column => {
        const value = item[column.accessor];
        const searchValue = column.sortValue
          ? column.sortValue(item)
          : React.isValidElement(value)
          ? typeof value.props.children === 'string'
            ? value.props.children
            : value.props.value !== undefined
            ? value.props.value
            : value
          : value;
        return searchValue
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      })
    );
  }, [searchTerm, sortedData, columns, enableSearch]);

  // Pagination logic (only if pagination is enabled).
  const paginatedData = useMemo(() => {
    if (!rowsPerPage) return filteredData;
    const startIndex = (currentPage - 1) * rowsPerPage;
    return filteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredData, currentPage, rowsPerPage]);

  const requestSort = key => {
    if (!enableSorting) return;
    let direction = 'descending';
    if (sortConfig.key === key && sortConfig.direction === 'descending') {
      direction = 'ascending';
    }
    setSortConfig({ key, direction });
  };

  const totalPages = rowsPerPage ? Math.max(1, Math.ceil(filteredData.length / rowsPerPage)) : 1;

  const renderSortIcon = columnKey => {
    if (!enableSorting) return null;

    const isColumnSorted = sortConfig.key === columnKey;
    const classes = classnames('icon', {
      'ion-arrow-up-c': isColumnSorted && sortConfig.direction === 'ascending',
      'ion-arrow-down-c': !isColumnSorted || sortConfig.direction === 'descending',
    });
    return (
      <SortIconWrapper className={isColumnSorted ? 'sorted' : ''}>
        <i className={classes} />
      </SortIconWrapper>
    );
  };

  return (
    <div>
      {enableSearch && (
        <SearchWrapper>
          <SearchInput
            data-aid="interactiveTable-searchInput"
            disabled={isLoading}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search..."
            type="text"
            value={searchTerm}
          />
        </SearchWrapper>
      )}

      <Table zebra>
        <StyledTHead>
          <TR>
            {columns.map(column => (
              <StyledTH
                align={column.align}
                data-aid="interactiveTable-columnHeader"
                key={column.accessor}
                onClick={() => requestSort(column.accessor)}
              >
                <THContent align={column.align}>
                  <HeaderText align={column.align}>
                    {column.align === 'right' && enableSorting && renderSortIcon(column.accessor)}
                    {column.header}
                    {column.align !== 'right' && enableSorting && renderSortIcon(column.accessor)}
                  </HeaderText>
                </THContent>
              </StyledTH>
            ))}
          </TR>
        </StyledTHead>
        <TBody>
          {!isLoading &&
            paginatedData.map((row, index) => (
              <StyledTR data-aid="interactiveTable-row" key={index}>
                {columns.map(column => (
                  <StyledTD align={column.align} data-aid="interactiveTable-cell" key={column.accessor}>
                    {row[column.accessor]}
                  </StyledTD>
                ))}
              </StyledTR>
            ))}
        </TBody>
      </Table>
      {isLoading && (
        <SpinnerContainer data-aid={`interactiveTable-loadingSpinner`}>
          <Spinner size={48} stroke={3} />
        </SpinnerContainer>
      )}

      {/* Pagination Controls (Only if enabled). */}
      {!isLoading && rowsPerPage && (
        <Pagination>
          <Button
            data-aid="interactiveTable-previousButton"
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          >
            Previous
          </Button>
          <span data-aid="interactiveTable-paginationText">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            data-aid="interactiveTable-nextButton"
            disabled={currentPage >= totalPages}
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          >
            Next
          </Button>
        </Pagination>
      )}
    </div>
  );
};

InteractiveTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['left', 'right', 'center']),
      sortValue: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  enableSearch: PropTypes.bool,
  enableSorting: PropTypes.bool,
  enablePagination: PropTypes.bool,
};

const Table = styled(CommonTable)`
  border: none;
  padding: ${p => p.theme.spacing.medium};
`;

export const SortIconWrapper = styled.span`
  flex-shrink: 0;
  opacity: 0;
  padding: ${p => p.theme.spacing.small};
  transition: opacity 0.2s ease;

  &.sorted {
    opacity: 1;
  }
`;

export const StyledTHead = styled(THead)`
  background-color: transparent;
`;

export const StyledTR = styled(TR)`
  align-items: center;
  &:hover {
    background-color: ${p => p.theme.colors.gray300} !important;
  }
`;

export const StyledTH = styled.th`
  background-color: transparent;
  color: ${p => p.theme.colors.black};
  cursor: pointer;
  padding-bottom: ${p => p.theme.spacing.insetMedium};
  text-align: ${p => p.align || 'left'};

  &:hover ${SortIconWrapper} {
    opacity: 1;
  }
`;

export const THContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: ${p => (p.align === 'right' ? 'flex-end' : 'flex-start')};
  padding: ${p => p.theme.spacing.small};
  width: auto;
`;

export const HeaderText = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing.xsmall};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledTD = styled.td`
  padding: ${p => p.theme.spacing.medium};
  text-align: ${p => p.align || 'left'};
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${p => p.theme.spacing.medium};
  padding-top: ${p => p.theme.spacing.medium};
`;

const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 300px;
  justify-content: center;
  width: 100%;
`;

const SearchWrapper = styled.div`
  margin-bottom: ${p => p.theme.spacing.medium};
  width: 300px;
`;

export default InteractiveTable;
