import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { AnswerPerformanceDateRange } from 'models/answer_performance';
import AnswerPerformanceTable from 'components/answer_performance/answer_performance_table';
import connect from 'components/lib/connect';
import DropdownMenu from 'components/common/dropdown_menu_v2';
import FilterAnswerPerformance from 'actions/answer_performance/filter_answer_performance';
import { H1 } from 'components/common/headers';
import PageLayout from 'components/page_layout';

export function AnswerPerformance({ audiences, selectedAudienceId, selectedDateRange, onChangeFilter }) {
  const [audienceId, setAudience] = useState(selectedAudienceId || '');
  const [dateRange, setDateRange] = useState(selectedDateRange || '1d');

  const audienceText = _.find(audiences, o => o.value === audienceId)?.label;
  const onChangeAudienceFilter = useCallback(
    newAudienceId => {
      onChangeFilter({ audienceId: newAudienceId, dateRange });
      setAudience(newAudienceId);
    },
    [dateRange, onChangeFilter]
  );

  const dateRangeText = _.find(dateRangeOptions, o => o.value === dateRange)?.label;
  const onChangeDateRangeFilter = useCallback(
    newDateRange => {
      onChangeFilter({ audienceId, dateRange: newDateRange });
      setDateRange(newDateRange);
    },
    [audienceId, onChangeFilter]
  );

  return (
    <PageLayout className="answer-performance-pageWrapper" id="answer-performance">
      <AnswerPerformancePage>
        <H1>Answers Performance</H1>
        <p>
          The table below shows which Public Answers were referenced by Gladly’s AI as it generated a response to a
          Customer inquiry. {/* TODO sc-201756: Update href */}
          <a href="https://help.gladly.com/docs/what-is-answers/" rel="noopener noreferrer" target="_blank">
            Learn more.
          </a>
        </p>
        <Filters>
          <StyledDropdownWrapper>
            <DropdownMenu
              className="date-range-filter"
              onSelect={onChangeDateRangeFilter}
              options={dateRangeOptions}
              text={dateRangeText}
            />
          </StyledDropdownWrapper>
          {!!audiences.length && (
            <StyledDropdownWrapper>
              <DropdownMenu
                className="audience-filter"
                onSelect={onChangeAudienceFilter}
                options={audiences}
                text={audienceText}
              />
            </StyledDropdownWrapper>
          )}
        </Filters>
        <AnswerPerformanceTable />
      </AnswerPerformancePage>
    </PageLayout>
  );
}

AnswerPerformance.propTypes = {
  audiences: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  selectedAudienceId: PropTypes.string,
  selectedDateRange: PropTypes.string,
  onChangeFilter: PropTypes.func,
};

const AnswerPerformancePage = styled.div`
  margin: 40px 64px;
`;

const Filters = styled.div`
  display: flex;
  padding: ${p => p.theme.spacing.medium} 0;
`;

const StyledDropdownWrapper = styled.div`
  margin-right: ${p => p.theme.spacing.inlineMedium};
`;

function mapStateToProps({ getProvider }) {
  const audiences = _.map(getProvider('audiences').findAll(), audience => ({
    value: audience.id,
    label: audience.name,
  })).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  if (audiences?.length) {
    audiences.unshift({ label: 'All Audiences', value: '' });
  }

  const location = getProvider('currentLocation').get();

  return {
    audiences,
    selectedAudienceId: location.audienceId,
    selectedDateRange: location.dateRange,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onChangeFilter: ({ audienceId, dateRange }) => {
      executeAction(FilterAnswerPerformance, { audienceId, dateRange });
    },
  };
}

const AnswerPerformanceContainer = connect(mapStateToProps, mapExecuteToProps)(AnswerPerformance);

export default AnswerPerformanceContainer;

const dateRangeOptions = [
  { label: 'Last 1 day', value: AnswerPerformanceDateRange.LAST_1_DAY },
  { label: 'Last 7 days', value: AnswerPerformanceDateRange.LAST_7_DAYS },
  { label: 'Last 30 days', value: AnswerPerformanceDateRange.LAST_30_DAYS },
  { label: 'Last 90 days', value: AnswerPerformanceDateRange.LAST_90_DAYS },
];
